<template>

<div class="menuiserie">
<barre-navigation></barre-navigation>

    <div class="specialiteMenuiserie">
        <div class="blocMenuiserie">
            <div class="centrerMenuiserie">
                <div class="titreMenuiserie">
                    <h1> Menuiseries intérieures </h1>
                    <div class="ligneMenuiserie"></div>
                </div>
                <div class="descriptionMenuiserie">
                    <p> Il existe plusieurs façons de cloisonner ou de séparer les espaces de vie  d’un logement comme les  <span> portes battantes </span>, les <span> portes à galandage </span> ou encore les <span> verrières </span>. Le choix est fonction des contraintes techniques ou de l’esthétique souhaitée pour son intérieur.   Les portes battantes seront souvent choisies pour accéder aux espaces intimes comme  les chambres, salles  de bain ou toilettes alors que les portes à galandage seront plutôt destinées à séparer l’espace entre le salon, la salle à manger, la cuisine ou répondre à un souci de gain de place. 
                        Les verrières ont plus vocation à séparer les espaces tout en les sublimant, sans cloisonner car ces menuiseries ont l’avantage de laisser passer la lumière. 
                        Nous pouvons poser tous ces éléments s’ils sont liés à des travaux de plaquisterie.
                    </p>
                </div>
            </div>
            <div class="photosMenuiserie">
                    <img alt="image-verriere" src="../assets/menuiserie1.svg">
                    <div class="avapMenuiserie">
                        <cartes-avant-apres imgav="poele1.png" imgap="poele2.png"></cartes-avant-apres>
                    </div>
            </div>
            <div class="imgMenuiserie">
                <cartes-avant-apres-pages-details imgav="porteGalandageAv.png" imgap="porteGalandageAp.png"></cartes-avant-apres-pages-details>
            </div>
        </div>
    </div>
</div>

</template>

<script>

    import BarreNavigation from '../components/BarreNavigation.vue'
    import CartesAvantApres from '../componentschild/CartesAvantApres.vue'
    import CartesAvantApresPagesDetails from '../componentschild/CartesAvantApresPagesDetails.vue'

    export default {
        name: 'Menuiseries',
        components: {
        BarreNavigation, CartesAvantApres, CartesAvantApresPagesDetails
        }
    }

</script>

<style scoped>

    .specialiteMenuiserie {

        padding-right: 14rem;
        padding-left: 14rem;

    }

    .blocMenuiserie {

        padding: var(--padding-haut-bas-section);

    }

    .blocMenuiserie h1 {

        font-size: 36px;
        font-weight: bold;
        color: #333F50;
        padding-bottom: 40px;

    }

    .blocMenuiserie p {

        font-size: 16px;
        color: #333F50;

    }

    .centrerMenuiserie {

        display: flex;
        justify-content: space-evenly;

    }

    .ligneMenuiserie {

        background-color: #7C928F;
        height: 10px;
        width: 400px;

    }

    .titreMenuiserie h1 {

        font-size: 32.6px;

    }

    .descriptionMenuiserie {

        margin-left: 50px;
        font-size: 20px;
        

    }

    .descriptionMenuiserie p {

        line-height: var(--line-height-pagesdetails);

    }

    .descriptionMenuiserie span {

        font-weight: bold;

    }

    .imgMenuiserie {

        display: flex;
        justify-content: center;
        padding-top: 70px;

    }

    .photosMenuiserie {

        display: flex;
        justify-content: space-around;
        padding-top: 70px;

    }

    .avapMenuiserie {

        height: 400px;

    }

    @media screen and (max-width: 1550px) {

        .photosMenuiserie {

            padding-top: 40px;

        }

    }

    @media screen and (max-width: 1500px) {

        .centrerMenuiserie {

            padding-bottom: 50px;

        }


        .photosMenuiserie {

            height: 338px;
            width: 500px;
            margin: 0 auto;

        }

        .avapMenuiserie {

            display: none;

        }

    }

    @media screen and (max-width: 1400px) {

        .centrerMenuiserie {

            padding-bottom: 0px;

        }

        .descriptionMenuiserie p {

            line-height: var(--line-height-medium);

        }

    }


    @media screen and (max-width: 1300px) {

        .ligneMenuiserie {
            
            width: 300px;

        }

        .imgMenuiserie {

            padding-top: 60px;

        }

        .imgMenuiserie img {

            height: 90%;
            width: 90%;

        }

    }

    @media screen and (max-width: 1110px) {

        .centrerMenuiserie {

            flex-direction: column;
            align-items: center;
            padding-bottom: 10px;

        }

        .titreMenuiserie h1 {

            text-align: center;

        }

        .ligneMenuiserie {

            width: 500px;
            height: 8px;

        }

        .descriptionMenuiserie {

            padding-top: 30px;
            display: flex;
            justify-content: center;
            margin-left: 0px;

        }

        .descriptionMenuiserie p {

            width: 500px;
            text-align: justify;

        }

        .imgMenuiserie img {

            height: 85%;
            width: 85%;

        }

        .photosMenuiserie {

            justify-content: center;

        }

    }

    @media screen and (max-width: 1000px) {

        .imgMenuiserie {

            justify-content: center;

        }

        .imgMenuiserie img {

            padding-top: 30px;

        }

    }

    @media screen and (max-width: 750px) {

        .specialiteMenuiserie {

            padding-right: 6rem;
            padding-left: 6rem;
            
        }

        .photosMenuiserie {

            width: 0px;

        }

        .photosMenuiserie img {

            height: 284px;
            width: 420px;

        } 

    }

    @media screen and (min-width: 670px) and (max-width: 870px) {

        .blocMenuiserie {

            padding-top: 60px;

        }

    }

    @media screen and (max-width: 550px) {

        .specialiteMenuiserie {

            padding-right: 1rem;
            padding-left: 1rem;

        }

        .blocMenuiserie {

            padding-right: 1rem;
            padding-left: 1rem;

        }

        .titreMenuiserie h1 {

            font-size: 24.4px;
            text-align: center;
            padding-bottom: 30px;
            width: 100%;

        }

        .ligneMenuiserie {

            width: 300px;
            height: 6px;

        }

        .descriptionMenuiserie p {

            width: 300px;
            font-size: 14px;

        }

        .photosMenuiserie {

            height: 250px;

        }

        .photosMenuiserie img {

            height: 190px;
            width: 281px;

        }

    }

</style>